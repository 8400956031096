import { useEffect, useRef } from "react";

const useCardRotation = () => {
  const cardRef = useRef(null);
  const glowRef = useRef(null);

  useEffect(() => {
    const card = cardRef.current;
    const glow = glowRef.current;

    if (!card || !glow) return;

    const rotateToMouse = (e) => {
      const bounds = card.getBoundingClientRect();
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      const leftX = mouseX - bounds.x;
      const topY = mouseY - bounds.y;
      const center = {
        x: leftX - bounds.width / 2,
        y: topY - bounds.height / 2,
      };
      const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

      card.style.transform = `
        scale3d(1.02, 1.02, 1.02)
        rotate3d(
          ${center.y / 100},
          ${-center.x / 100},
          0,
          ${Math.log(distance) * 1}deg
        )
      `;

      glow.style.backgroundImage = `
        radial-gradient(
          circle at
          ${center.x * 2 + bounds.width / 2}px
          ${center.y * 2 + bounds.height / 2}px,
          #ffffff55,
          #0000000f
        )
      `;
    };

    const removeListener = () => {
      card.style.transform = "";
      glow.style.backgroundImage = "";
    };

    card.addEventListener("mousemove", rotateToMouse);
    card.addEventListener("mouseleave", removeListener);

    return () => {
      card.removeEventListener("mousemove", rotateToMouse);
      card.removeEventListener("mouseleave", removeListener);
    };
  }, []);

  return { cardRef, glowRef };
};

export default useCardRotation;
