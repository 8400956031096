/**
 * @name CardDefault
 * @description A card component for displaying account types. Must have used inside an CardDefaults nCollection component.
 */

import Image from "next/image";

import Heading4 from "@/components/utility/heading4";
import Paragraph from "@/components/utility/paragraph";
import { CardT, DesignSystemComponent } from "@/types/design-system-types";
import { addHttps, cn } from "@/utils/helpers";
import ComponentDebugger from "@/utils/system/component-debugger";
import { ComponentError } from "@/utils/system/component-error";
import Page from "@/utils/system/page";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Button from "../atoms/button";
import RichText from "../atoms/rich-text";
import Tile from "./tile";
import useCardRotation from "@/hooks/useCardRotation";
import { ElementType } from "react";
import Link from "@/components/utility/link";

const Card = ({
  data, // All incoming Contentful data
  debug, // Debug framer

  // Contentful controlled Props
  tileSlot = data?.fields.tileSlot, // Used to be badgeSlot
  smallText = data?.fields.smallText, // Used to be Caption
  image = data?.fields.image,
  imageSize = data?.fields.imageSize ?? "auto",
  title = data?.fields.title,
  description = data?.fields.description,
  bulletPointType = data?.fields.bulletPointType,
  supportingText = data?.fields.supportingText,
  primaryButton = data?.fields.primaryButton,
  secondaryButton = data?.fields.secondaryButton,
  href = data?.fields?.href,
  theme,

  // Non contentful props
  type,
  isClickable = href ? true : false,

  // Internal props
  className,
  ...props
}: DesignSystemComponent<CardT>) => {
  let { cardRef, glowRef }: any = useCardRotation();
  const isAnimate = data?.fields?.internalName?.includes("animate");

  if (!isAnimate) {
    cardRef = null;
    glowRef = null;
  }

  try {
    theme = theme ? theme : data?.fields.theme;

    const themeClass = Page.getThemeClass(theme);
    const typeClass = type === "news" ? "n-card-news" : "";

    const hasTheme = data?.fields.theme || theme ? "has-theme" : "";
    const ParentComponent = href ? Link : ("div" as ElementType);

    return (
      <ParentComponent
        {...props}
        {...(href && {
          href,
          "data-anchor-type": "card",
        })}
        className={cn("n-card-container")}
      >
        <div
          ref={cardRef}
          className={cn(
            `n-card ${hasTheme} ${themeClass} ${typeClass}`,
            className,
            isAnimate && "card-animation",
            isClickable && "n-card-clickable"
          )}
        >
          {/* Debug framer */}
          {debug}

          {/* Tile Slot */}
          {tileSlot && (
            <div className="n-card-tileslot">
              {tileSlot && (
                <Tile
                  debug={<ComponentDebugger data={tileSlot} />}
                  data={tileSlot}
                  // className="min-h-[165px] 1280:min-h-[137px] 1440:min-h-[115px]"
                  className="lg:min-h-[165px] 1440px:min-h-[115px] 1280px:min-h-[137px]"
                  smallText={tileSlot?.fields?.smallText} // Zero width space to keep the height consistent
                />
              )}
            </div>
          )}

          <div className="flex h-full flex-col justify-between relative">
            <div>
              {/* Image */}
              {image && (
                <div
                  className={cn("n-card-imageslot accent-border")}
                  onClick={() => Page.editField(data, "image")}
                >
                  {/* Contentful image */}
                  {image?.fields?.file?.details ? (
                    <Image
                      className={cn("n-card-image", [
                        `w-${imageSize}`,
                        imageSize === "auto" && "md:max-w-[50%]", // testing fix for responsive image
                      ])}
                      src={addHttps(`${image.fields.file.url}`)}
                      alt=""
                      width={image.fields.file.details.image?.width}
                      height={image.fields.file.details.image?.height}
                    />
                  ) : (
                    // Any image URL
                    <Image
                      // className={cn("n-card-image", [`w-${imageSize}`])}
                      className={cn("n-card-image", [
                        `w-${imageSize}`,
                        imageSize === "auto" && "md:max-w-[50%]", // testing fix for responsive image
                      ])}
                      src={image as any}
                      alt=""
                      sizes="100vw"
                      width={0}
                      height={0}
                    />
                  )}
                </div>
              )}

              {/* Small text */}
              {smallText && (
                <Paragraph type="caption" className="n-card-smalltext">
                  {smallText}
                </Paragraph>
              )}

              {/* Header */}
              {title && (
                <Heading4
                  className="n-card-title"
                  onClick={() => Page.editField(data, "title")}
                >
                  {title}
                </Heading4>
              )}

              {/* Description */}
              {description?.content ? (
                // <div onClick={() => Page.editField(data, "description")}>
                <div>
                  <RichText
                    data={description}
                    theme={theme}
                    bulletPointType={bulletPointType}
                    sys={data?.sys}
                    fieldName="description"
                  />
                </div>
              ) : (
                description
              )}
            </div>

            <div>
              {/* Supporting text */}
              {supportingText && "content" in supportingText ? (
                <Paragraph
                  onClick={() => Page.editField(data, "supportingText")}
                  fontSize="sm"
                  className="mt-4"
                >
                  {documentToReactComponents(supportingText)}
                </Paragraph>
              ) : (
                <div className="mt-4">{supportingText}</div>
              )}

              {/* CTA Buttons */}
              {(primaryButton || secondaryButton) && (
                <div className="n-card-buttons">
                  {primaryButton && (
                    <Button
                      onClick={() => Page.editField(data, "primaryButton")}
                      type="primary"
                      data={primaryButton}
                    />
                  )}
                  {secondaryButton && (
                    <Button
                      onClick={() => Page.editField(data, "secondaryButton")}
                      type="secondary"
                      data={secondaryButton}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </ParentComponent>
    );
  } catch (error) {
    return <ComponentError error={error} data={data} />;
  }
};

export default Card;
